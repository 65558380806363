<template>
  <main class="flex-1 overflow-x-hidden overflow-y-auto px-2">
    <div class="container mx-auto">
      <h1
        class="flex items-center font-sans font-bold break-normal text-indigo-500  text-xl md:text-2xl py-2"
      >
        Discord (D.) Member List
      </h1>

      <div class="mb-4">
        <!-- component -->
        <div id="" class="">
          <table id="" class="border-collapse w-full text-sm">
            <thead>
              <tr>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Member ID
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Discord ID
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  D. Username
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  D. Email
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  D. Avatar
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  D. Discriminator
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  D. Public Flags
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  D. Flags
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  D. Locale
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  D. Mfa Enabled
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Is Active
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  Registration TS
                </th>
                <th
                  class="p-3 font-bold bg-gray-200 text-gray-600 border border-gray-300 hidden md:table-cell"
                >
                  LostLogin TS
                </th>
              </tr>
            </thead>
            <tbody id="memberList">
              <tr
                class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0"
              >
                <td
                  class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static"
                  colspan="13"
                >
                  Please wait...
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import * as moment from "moment";

export default {
  name: "NewTickets",
  data() {
    return {
      //
    };
  },
  created() {
    this.get();
  },
  methods: {
    get() {
      this.$axios
        .get(`${this.$api}/member/get`)
        .then(res => {
          const data = res.data.data;
          let rawHtml = "";
          data.forEach(tr => {
            rawHtml += `<tr class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0" id="tix-data-wait">
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.MemberID
                }
                </td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.DiscordID
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.DiscordUsername
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.DiscordEmail
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.DiscordAvatar ? tr.DiscordAvatar : "N/A"
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.DiscordDiscriminator
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.DiscordPublicFlags
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.DiscordFlags
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.DiscordLocale
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.DiscordMfaEnabled
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${
                  tr.IsActive == 1 ? "Yes" : "No"
                }</td>
                <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${moment(
                  moment(tr.CreatedAt).utcOffset("-06:00")
                ).format("MMMM Do YYYY, hh:mm A")}
                </td>
                 <td class="w-full lg:w-auto p-3 text-gray-800 text-center border border-b block md:table-cell relative lg:static">${moment(
                   moment(tr.UpdatedAt).utcOffset("-06:00")
                 ).format("MMMM Do YYYY, hh:mm A")}
                </td>
              </tr>`;
          });
          document.getElementById("memberList").innerHTML = rawHtml;
        })
        .catch(err => {
          console.log(err);
          document.getElementById(
            "memberList"
          ).innerHTML = `<tr class="bg-white lg:hover:bg-gray-100 flex lg:table-row flex-row lg:flex-row flex-wrap lg:flex-no-wrap mb-10 lg:mb-0">
                  <td class="w-full lg:w-auto p-3 text-red-800 text-center border border-b block md:table-cell relative lg:static" colspan="13">
                      Something went wrong, please try again.
                  </td>
              </tr>
          `;
        });
    }
  }
};
</script>

<style>
table {
  width: 100%;
}
table td {
  overflow-wrap: anywhere;
}
</style>
